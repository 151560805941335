@import '../../../variables.scss';
@import '../../../common.scss';

.spelling-wrapper {
    @extend .game-wrapper;

    background-color: #fff;
    padding: 24px;

    span.ant-typography {
        font-size: 18px;
    }
}
