@import '../../variables.scss';
@import '../../common.scss';

$spell-tower-block-count: 40;
$spell-tower-background-colors: $main-color, $secondary-color;
$spell-tower-background-borders: $main-color-dark, $secondary-color-dark;

.spell-tower-wrapper {
    @extend .game-wrapper;
    display: flex;
    justify-content: end;
    flex-direction: column;

    &::before {
        opacity: 0.3;
        background-image: url('../../assets/games/spellTowerBg.png');
    }

    .spell-tower-top {
        text-align: center;
        font-size: 24px;
        font-weight: 800;
        margin-bottom: 24px;
    }

    .spell-tower-wrapper-inner {
        padding: 24px;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        .spell-tower {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            position: relative;

            @for $i from 1 through $spell-tower-block-count {
                $random-margin: random(20) + 1;
                $random-direction: random(2);
                $background-color: nth($spell-tower-background-colors, 1 + ($i % 2));
                $border-color: nth($spell-tower-background-borders, 1 + ($i % 2));

                div:nth-child(#{$i}) {
                    width: 100px;
                    height: 20px;
                    background-color: $background-color;
                    margin-bottom: -2px;
                    border-radius: 6px;
                    border: 1px solid $border-color;
                    box-shadow: inset 0 0 6px $border-color;
                    position: relative;

                    @if $random-direction == 1 {
                        left: $random-margin + px;
                    } @else {
                        right: $random-margin + px;
                    }

                    @media only screen and (max-width: 479px) {
                        width: 80px;
                    }
                }
            }
        }
    }
}

.spell-tower-game {
    @extend .game-wrapper;

    &-inner {
        padding: 24px;
    }

    &::before {
        opacity: 0.2;
        background-image: url('../../assets/games/spellTowerBg.png');
    }
}
