@import '../../variables.scss';
@import '../../common.scss';

.crossword-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.crossword-sidebar {
    @extend .game-wrapper;
    padding: 12px;
    background-color: #fff;
    margin-right: 20px;
    width: 100%;
    max-width: 200px;

    ul {
        list-style: none;
        padding: 0;
    }

    li {
        position: relative;
        margin-bottom: 5px;

        &.completed {
            text-decoration: line-through;
        }

        span {
            display: inline-block;
            width: 10px;
            height: 10px;
            margin-right: 5px;
            vertical-align: middle;
            position: relative;
            top: -1px;
        }
    }
}

.crossword-grid-container {
    @extend .game-wrapper;
    padding: 12px;
    background-color: #fff;
    display: grid;
    gap: 1px;

    &::before {
        opacity: 0.2;
        background-image: url('../../assets/games/crossWordPuzzleBg.png');
    }
}

.crossword-grid-item {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}

.crossword-wrapper {
    @extend .game-wrapper;

    &::before {
        opacity: 0.3;
        background-image: url('../../assets/games/crossWordPuzzleBg.png');
    }
}
