.game-wrapper {
    position: relative;
    min-height: 460px;
    overflow: hidden;
    width: 100%;
    margin-top: 24px;
    border: 1px solid #000;
    border-radius: 12px;

    &::before {
        content: '';
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.game-rules {
    background: #fff;
    padding: 12px;
    border-radius: 12px;
    position: relative;
    z-index: 1;

    h2 {
        margin-top: 0;

        &.ant-typography {
            font-size: 24px;
        }
    }

    .ant-typography {
        font-size: 16px;

        strong {
            font-size: 18px;
        }
    }
}

.results-wrapper {
    .results-title {
        &.ant-typography {
            font-size: 24px;
            margin-right: 5px;
        }
    }

    .results-ok {
        &.ant-typography {
            font-size: 24px;
            margin-left: 5px;
        }
    }

    .results-ko {
        &.ant-typography {
            font-size: 20px;
            margin-right: 5px;
        }
    }

    @media only screen and (max-width: 479px) {
        .results-title {
            &.ant-typography {
                font-size: 18px;
            }
        }

        .results-ok {
            &.ant-typography {
                font-size: 18px;
            }
        }

        .results-ko {
            &.ant-typography {
                font-size: 16px;
            }
        }
    }
}
