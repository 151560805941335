.hero {
    background-color: #fff;
    border-radius: 10px;
    padding: 24px;
    display: flex;
    flex-direction: row;

    h1 {
        &.ant-typography {
            font-size: 36px;
            margin-top: 0;
        }
    }

    img {
        max-width: 240px;
        margin-right: 24px;
    }

    .ant-typography {
        font-size: 18px;
    }

    @media only screen and (max-width: 479px) {
        padding: 12px;

        h1 {
            &.ant-typography {
                font-size: 18px;
            }
        }

        img {
            max-width: 100px;
            margin-right: 12px;
        }

        .ant-typography {
            font-size: 14px;
        }
    }

    @media only screen and (min-width: 480px) and (max-width: 767px) {
        img {
            max-width: 180px;
        }

        h1 {
            &.ant-typography {
                font-size: 24px;
            }
        }

        .ant-typography {
            font-size: 14px;
        }
    }
}
