@import './variables.scss';

body {
    margin: 0;
    background-color: #f5f5f5;
    min-width: 380px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;

    .ant-typography {
        &.powered {
            text-align: right;
        }
    }

    div {
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;

        a.first-link {
            &::after {
                content: '  |  ';
            }
        }
    }

    .ant-select .ant-select-selection-item {
        overflow: unset !important;
    }
}

.content-wrapper {
    padding: 48px 0;

    @media only screen and (max-width: 479px) {
        padding: 24px 0;
    }
}

.buy-m-a-coffee {
    max-width: 85px;
    display: flex;
    flex-direction: row;
    color: #000;

    img {
        margin-right: 5px;
    }
}

// Antd Overrides
.ant-card {
    .ant-card-meta-title {
        white-space: pre-line;
        line-height: 1.2;
    }

    @media only screen and (max-width: 479px) {
        .ant-card-body {
            padding: 12px;
        }
    }
}

main.ant-layout-content {
    max-width: 1620px;
    margin: 0 auto;
    padding: 100px 20px 40px;

    @media only screen and (max-width: 479px) {
        padding: 100px 12px 20px;
    }
}

// @media only screen and (max-width: 479px){  }
// @media only screen and (min-width: 480px) and (max-width: 767px){  }
// @media only screen and (min-width: 768px) and (max-width: 991px){  }
// @media only screen and (min-width: 992px){  }
