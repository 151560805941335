@import '../../variables.scss';
@import '../../common.scss';

.definition-master-wrapper {
    @extend .game-wrapper;

    &::before {
        opacity: 0.3;
        background-image: url('../../assets/games/definitionMasterBg.png');
    }

    .definition-master-inner {
        padding: 24px;

        @media only screen and (max-width: 479px) {
            h2.ant-typography {
                margin-top: 0;
                font-size: 26px;
            }
        }

        .ant-btn {
            &.next-btn {
                font-size: 24px;
                margin: 12px;
                height: auto;
                border: 1px solid $main-color-dark;
                background-color: $main-color;
            }
        }

        .ant-btn-default {
            &.letter-btn {
                font-size: 18px;
                width: 60px;
                height: 60px;
                border: 1px solid #000;
                margin: 12px;

                @media only screen and (max-width: 479px) {
                    margin: 6px;
                }
            }

            &.definition-btn {
                font-size: 18px;
                border: 1px solid #000;
                margin: 12px;
                max-width: 100%;
                text-wrap: wrap;
                height: auto;

                @media only screen and (max-width: 479px) {
                    font-size: 16px;
                }

                &.correct-answer {
                    border: 1px solid $main-color;
                    color: $main-color;
                }

                &.incorrect-answer {
                    border: 1px solid $danger-color;
                    color: $danger-color;
                }
            }
        }
    }

    .definition-master-answers {
        background-color: #fff;
        padding: 24px;
        border-radius: 12px;
        z-index: 1;
        position: relative;
    }

    .definition-master-quiz {
        display: flex;
        flex-direction: column;
        max-width: 700px;
        margin: 0 auto;
    }
}
