header {
    &.header.ant-layout-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #000;
        height: 78px;
        padding-inline: 20px;
        position: fixed;
        z-index: 10;
        width: 100%;
        box-sizing: border-box;

        @media only screen and (max-width: 991px) {
            padding-inline: 0;
        }
    }

    .logo {
        line-height: 1;

        img {
            height: 78px;
        }

        @media only screen and (max-width: 850px) {
            margin-left: 12px;

            img {
                height: 40px;
            }
        }
    }

    .ant-menu {
        line-height: 78px;
        background-color: #000;
        width: 80%;

        @media only screen and (max-width: 850px) {
            width: 70px;
        }

        .ant-menu-item-selected {
            background-color: transparent !important;
            font-weight: 800;
        }
    }

    .word-of-the-day {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-direction: column;
        min-width: 130px;
        padding-right: 10px;
    }
}
