@import '../../common.scss';

$heart-base-width: 30px;
$heart-side-width: 32px;
$heart-width: $heart-base-width + $heart-side-width;

.words-rain-wrapper {
    @extend .game-wrapper;

    &::before {
        opacity: 0.3;
        background-image: url('../../assets/games/wordsRainBg.png');
    }

    .words-rain-inner,
    .words-rain-results {
        z-index: 2;
        position: relative;
        padding: 24px;
    }

    .words-rain-word {
        cursor: pointer;
        border: 1px solid #000;
        border-radius: 12px;
        background-color: #fff;
        position: absolute;
        top: -50px;
        height: 50px;
        animation: fallDisappear 5s linear forwards;
    }

    .words-rain-lifes {
        position: absolute;
        right: 24px;
        bottom: 12px;
        user-select: none;

        #heart {
            position: relative;
            width: $heart-width;
            height: 50px;
            text-align: center;

            span {
                position: relative;
                z-index: 1;
                top: 3px;
            }
        }

        #heart::before,
        #heart::after {
            content: '';
            position: absolute;
            top: 0;
            width: $heart-side-width;
            height: 50px;
            border-radius: 50px 50px 0 0;
            background: #f45;
        }

        #heart::before {
            left: $heart-side-width;
            transform: rotate(-45deg);
            transform-origin: 0 100%;
        }

        #heart::after {
            left: 0;
            transform: rotate(45deg);
            transform-origin: 100% 100%;
        }
    }

    .words-rain-points {
        position: absolute;
        right: 24px;
        top: 12px;
        user-select: none;

        span {
            display: block;
            text-align: center;
        }
    }
}

@keyframes fallDisappear {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    100% {
        top: 110%;
        opacity: 0;
    }
}
